// COMPONENTE
/* Input Transparente con Etiqueta */
export const FieldTransparentInput = ({ id, name, placeholder, register, registerOptions = { required: true } }) => (
    <div className="relative">
		<input
            autoComplete="off"
            className="w-full h-10 bg-transparent border-b-2 border-white text-white placeholder-transparent peer focus:outline-none"
            id={id}
            name={name}
            placeholder={placeholder}
            type="text"
            {...register(name, registerOptions)}
        />

		<label
            className="absolute left-0 -top-3.5 text-white text-sm font-medium transition-all peer-focus:-top-3.5 peer-focus:text-white peer-focus:text-sm peer-placeholder-shown:top-2 peer-placeholder-shown:text-white peer-placeholder-shown:text-base"
            htmlFor={name}
        >
            { placeholder }
        </label>
	</div>
);
