/* Context */
import { AuthContext } from '../context/authContext';
/* Router */
import { Navigate } from 'react-router-dom';
/* Hooks */
import { useContext } from 'react';

// COMPONENTE
/* Ruta Pública */
export const PublicRoute = ({ children }) => {
    /* Contexto */
    const { user } = useContext(AuthContext);
    
    // RETORNO
    return user.logged ? <Navigate to='/' /> : children;
}
