// COMPONENTE
/* Botón con Icono */
export const IconButton = ({ clickFunction, disabled = false, icon, title = "" }) => (
    <button
        className="focus:outline-none"
        disabled={disabled}
        onClick={clickFunction}
        title={title}
        type="button"
    >
        { icon }
    </button>
);
