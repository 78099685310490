import axios from 'axios';
/* Context */
import { AuthContext } from '../auth/context/authContext';
/* Hooks */
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
/* Services */
import CookieService from '../services/CookieService';
import { headers } from '../services/Headers';
/* Types */
import { types } from '../auth/types/types';
/* Utils */
import { endpoint } from '../utils/helpers';
import { getRemainingTimeUntilMsTimestamp } from '../utils/countdownTimer';

// CONSTANTES
/* Tiempo Restante por Default */
const defaultRemainingTime = {
    seconds: '00',
    minutes: '00',
    hours: '00'
}

// FUNCIONES
/* Obtención del Timestamp de la Fecha */
const toTimestamp = (date) => Math.round(date.getTime() / 1000);

// HOOK
/* Manejo del Contador de Expiración de la Sesión */
export const useCountdownTimer = (clicks) => {
    /* Contexto */
    const { dispatch } = useContext(AuthContext);
    /* Navegación */
    const navigate = useNavigate();
    
    // CONSTANTES
    const [ remainingTime, setRemainingTime ] = useState(parseInt(defaultRemainingTime));                               /* Tiempo Restante */
    const [ actualTime ] = useState(new Date());                                                                        /* Fecha Actual */
    const [ expireTime ] = useState(120 * 60000);                                                                       /* Tiempo para Agregar para que la Fecha Actual Expire */
    const [ expireActualTime, setExpireActualTime ] = useState(new Date(actualTime.getTime() + expireTime));            /* Fecha de Expiración de la Sesión */
    const [ countdownTimestampMs, setCountdownTimestampMs ] = useState(`${toTimestamp(expireActualTime)}000`);          /* Timestamp del Contador */
    const [ open, setOpen ] = useState(false);                                                                          /* Estado de Apertura del Modal del Contador */
    
    // FUNCIONES
    /* Actualización del Tiempo Restante */
    const updateRemainingTime = (countdown) => setRemainingTime(getRemainingTimeUntilMsTimestamp(countdown));

    /* Funcionalidad del Logout */
    const handleLogout = async () => {
        setOpen(false);

        // CONSTANTES
        /* Token */
        const { token } = CookieService.get('user');

        await axios.post(`${endpoint}/logout`, {}, headers(token))
        .then(() => {
            dispatch({ type: types.logout });
            navigate('/login', { replace: true });
        })
        .catch((err) => console.log(err, 'err handleLogout'));
    }

    /* Agregación de Más Tiempo a la Sesión */
    const addMoreTime = async () => {
        // VARIABLES
        let newActualTime = new Date();                                             /* Fecha Actual */
        let newExpireTime = new Date(newActualTime.getTime() + expireTime);         /* Nuevo Tiempo de Expiración */

        setExpireActualTime(newExpireTime);
        setCountdownTimestampMs(`${toTimestamp(newExpireTime)}000`);
    }

    // HOOKS
    /* Renderizado del Contador */
    useEffect(() => {
        // FUNCIONES
        /* Intervalo para la Actualización del Tiempo Restante */
        const interval = setInterval(() => updateRemainingTime(parseInt(countdownTimestampMs)), 1000);

        // RETORNO
        return () => clearInterval(interval);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ parseInt(countdownTimestampMs) ]);

    /* Renderizado del Modal con el Tiempo Restante */
    useEffect(() => {
        // CONDICIONAL
        /* Comprobación de Segundos del Tiempo Restante Diferente de undefined */
        if (remainingTime.seconds !== undefined) {
            // CONDICIONAL
            /* Comprobación de Tiempo Restante Menor o Igual a 30 Segundos */
            if (parseInt(remainingTime.seconds) <= 30 && remainingTime.minutes === '00' && remainingTime.hours === '00') {   
                // CONDICIONAL
                /* Comprobación del Estado de Abierto del Modal del Contador */
                if (open === false) setOpen(true);
            }

            // CONDICIONAL
            /* Comprobación de la Expiración de la Sesión */
            if (remainingTime.seconds === '00' && remainingTime.minutes === '00' && remainingTime.hours === '00') handleLogout();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ remainingTime ]);

    /* Renderizado de la Agregación de Más Tiempo a la Sesión */
    useEffect(() => {
        addMoreTime();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ clicks.current ]);

    // RETORNO
    return { remainingTime, open, setOpen, handleLogout, addMoreTime }
}
