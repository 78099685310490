/* Icons */
import { FaMoneyBillWave, FaUserTie } from 'react-icons/fa';
import { FaTags } from 'react-icons/fa6';
import { HiDocumentText } from 'react-icons/hi2';
import { PiBankFill, PiHandCoinsFill } from 'react-icons/pi';
import { BiSolidReport } from "react-icons/bi";

// CONSTANTES

/* Links para el Menú de Cobranza */
export const cobranzaLinks = [
    {
        icon: <FaTags className='mr-2 w-5 h-5 group-hover:text-purple' />,
        title: 'Conceptos'
    },
    {
        icon: <HiDocumentText className='mr-2 w-5 h-5 group-hover:text-blue' />,
        title: 'Facturas'
    },
    {
        icon: <FaMoneyBillWave className='mr-2 w-5 h-5 group-hover:text-green-700' />,
        title: 'Pagos'
    },
    {
        icon: <PiHandCoinsFill className='mr-2 w-5 h-5 group-hover:text-amber-400' />,
        title: 'Cobranzas'
    },
]

/* Links para la Barra de Navegación */
export const navbarLinks = [
    {
        icon: <PiBankFill className='mr-2 w-5 h-5 group-hover:text-gray' />,
        title: 'Bancos'
    },
    {
        icon: <FaUserTie className='mr-2 w-5 h-5 px-0.5 group-hover:text-orange-500' />,
        title: 'Clientes'
    },
    {
        icon: <BiSolidReport className='mr-2 w-5 h-5 group-hover:text-emerald-dark' />,
        title: 'Reportes'
    },
]