/* Types */
import { types } from '../types/types';

// FUNCIONES
/* Reducer para la Autenticación */
export const AuthReducer = (state = {}, action) => {
    // CONDICIONAL
    /* Comprobación del Tipo de Acción */
    switch (action.type) {
        case types.login:
            // RETORNO
            return {
                ...action.payload,
                logged: true,
            }

        case types.logout:
            // RETORNO
            return {
                logged: false,
            }

        default:
            // RETORNO
            return state;
    }
}
