/* Components */
import { ModalLayout } from './ModalLayout';
import { TextIconButton } from '../buttons/TextIconButton';
/* Icons */
import { BsFillExclamationCircleFill } from 'react-icons/bs';
/* Hooks */
import { useCountdownTimer } from '../../hooks/useCountdownTimer';

// COMPONENTE
/* Modal del Contador de Expiración */
export const CountdownTimerModal = ({ clicks }) => {
    /* Manejo del Contador de Expiración de la Sesión */
    const { remainingTime, open, setOpen, handleLogout, addMoreTime } = useCountdownTimer(clicks);

    // RETORNO
    return (
        <ModalLayout
            canClose={false}
            icon={<BsFillExclamationCircleFill className='text-lavander' />}
            open={open}
            setOpen={setOpen}
            title='¡Su sesión va a expirar pronto!'
        >
            {/* Contenido */}
            <div className="mb-4 grid grid-cols-3 gap-3">
                <div className="col-span-3 text-center">
                    <p className="block text-blue-darker text-md font-bold">¿Desea mantener su sesión activa?</p>
                </div>
                <div className="col-span-3 text-center">
                    <p className="block text-sm">
                        Segundos Restantes: <span className="text-ruby font-bold">{ remainingTime.seconds }</span>
                    </p>
                </div>
            </div>

            {/* Botones de Acciones*/}
            <div className="py-2 text-center">
                <TextIconButton
                    bgColor='bg-gray'
                    bgColorHover='hover:bg-gray-dark'
                    onClick={() => handleLogout()}
                    padding='px-3 py-1'
                    text='No'
                />
                <TextIconButton
                    bgColor='bg-blue'
                    bgColorHover='hover:bg-blue-dark'
                    onClick={() => {
                        addMoreTime();
                        setOpen(false);
                    }}
                    padding='px-3.5 py-1'
                    text='Si'
                />
            </div>
        </ModalLayout>
    );
}
