/* Components */
import { IconButton } from '../buttons/IconButton';
/* Icons */
import { FaEye, FaEyeSlash } from 'react-icons/fa';
/* Hooks */
import { useState } from 'react';

// COMPONENTE
/* Input Transparente para Contraseñas con Etiqueta */
export const PasswordTransparentInput = ({ id, name, placeholder, register }) => {
    // CONSTANTES
    /* Estado de Muestra de la Contraseña */
    const [ show, setShow ] = useState(false);

    // RETORNO
    return (<>
        <div className="relative">
            <input
                autoComplete="off"
                className="w-full h-10 bg-transparent border-b-2 border-white text-white placeholder-transparent peer focus:outline-none"
                id={id}
                name={name}
                placeholder={placeholder}
                type={show ? 'text' : 'password'}
                {...register(name, { required: true })}
            />

            <label
                className="absolute left-0 -top-3.5 text-white text-sm font-medium transition-all peer-focus:-top-3.5 peer-focus:text-white peer-focus:text-sm peer-placeholder-shown:top-2 peer-placeholder-shown:text-base peer-placeholder-shown:text-white"
                htmlFor={name}
            >
                { placeholder }
            </label>
            
            <div className="absolute right-2.5 inset-y-0 flex items-center transition-all">
                <IconButton
                    clickFunction={() => setShow(!show)}
                    icon={show
                        ? (<FaEyeSlash className='text-white text-lg' />)
                        : (<FaEye className='text-white text-lg' />)
                    }
                />
            </div>
        </div>
    </>);
}
