import React from 'react';
import ReactDOM from 'react-dom/client';
/* Application */
import { Dolos } from './Dolos';
/* Styles */
import './index.css';

// CONSTANTES
/* Raíz de la Aplicación */
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<Dolos />);
