import axios from 'axios';
/* Context */
import { AuthContext } from '../auth/context/authContext';
/* Router */
import { AppRouter } from './AppRouter';
/* Components */
import { Footer } from '../components/Footer';
import { Navbar } from '../components/Navbar';
/* Hooks */
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
/* Services */
import CookieService from '../services/CookieService';
import { headers } from '../services/Headers';
/* Utils */
import { endpoint } from '../utils/helpers';
/* Types */
import { types } from '../auth/types/types';

// ROUTER
/* Rutas Principales */
export const Main = () => {
     /* Contexto */
     const { dispatch } = useContext(AuthContext);
     const data = CookieService.get('user');
     /* Navegación */
     const navigate = useNavigate();
     
    // FUNCIONES
    /* Manejador del Logout */
    const handleLogout = async () => {
        await axios.post(`${endpoint}/logout`, {}, headers(data.token))
         .then(() => {
             dispatch({ type: types.logout });
             navigate('/login', { replace: true });
         })
         .catch((err) => console.log(err, 'err handleLogout'));
     }

    // RETORNO
    return (
        <div className="w-full min-h-screen relative">
            <Navbar data={data} handleLogout={handleLogout} />

            <AppRouter />

            <Footer />
        </div>
    );
}
