/* Router */
import { Link } from 'react-router-dom';
/* Components */
import { MenuLayout } from './MenuLayout';
/* Icons */
import { MdAccountBalanceWallet } from 'react-icons/md';
/* Utils */
import { cobranzaLinks } from '../../utils/navigation';
/* Headless UI */
import { Menu } from '@headlessui/react';

// COMPONENTE
/* Menú de Cobranza */
export const CobranzaMenu = () => {
    // CONSTANTES
    /* Nuevo Arreglo de los Links del Menú de Cobranza */
    const resultLinks = [];
    
    cobranzaLinks.forEach((link, index) => resultLinks.push(
        <Menu.Item key={index}>
            <Link
                className='px-3 py-2 w-full inline-flex items-center group bg-white rounded-md text-ming-light text-sm font-medium hover:bg-ming-lighter hover:text-blue-darker'
                to={`/${link.title.toLowerCase()}`}
            >
                { link.icon } { link.title }
            </Link>
        </Menu.Item>
    ));

    // RETORNO
    return (
        <MenuLayout
            icon={<MdAccountBalanceWallet className='w-5 h-5 group-hover:text-brown'/>}
            title='Cobranza'
        >
            { resultLinks }
        </MenuLayout>
    );
}
