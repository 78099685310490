import { Fragment } from 'react';
/* Icons */
import { FaChevronDown, FaChevronUp } from 'react-icons/fa6';
/* Headless UI */
import { Menu, Transition } from '@headlessui/react';

// COMPONENTE
/* Plantilla de un Menú Desplegable */
export const MenuLayout = ({ children, icon, title }) => (
    <Menu as='div' className='relative inline-block text-left'>
        {({ open }) => (<>
            {/* Botón de Apertura del Menú */}
            <Menu.Button className='px-3 py-2 inline-flex items-center group bg-white rounded-md text-ming-light text-sm font-medium hover:bg-ming-lighter hover:text-blue-darker'>
                { icon }
                        
                <span className="mx-2">{ title }</span>

                {// CONDICIONAL
                /* Comprobación del Estado de Apertura del Menú */
                open === true 
                    ? (<FaChevronUp className='w-3.5 h-3.5 text-blue-darker' />)
                    : (<FaChevronDown className='w-3.5 h-3.5 text-blue-darker' />)
                }
            </Menu.Button>

            <Transition
                as={Fragment}
                enter='transition ease-out duration-100'
                enterFrom='transform opacity-0 scale-95'
                enterTo='transform opacity-100 scale-100'
                leave='transition ease-in duration-75'
                leaveFrom='transform opacity-100 scale-100'
                leaveTo='transform opacity-0 scale-95'
            >
                <Menu.Items className='mt-4 w-56 z-50 absolute origin-top-right bg-white rounded-md shadow-lg divide-y divide-gray-100 ring-1 ring-black ring-opacity-5 focus:outline-none'>
                    <div className="p-1">{ children }</div>
                </Menu.Items>
            </Transition>
        </>)}
    </Menu>
);
