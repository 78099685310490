import { Fragment } from 'react';
/* Components */
import { ModalContentLayout } from './ModalContentLayout';
/* Headless UI */
import { Dialog, Transition } from '@headlessui/react';

// COMPONENTE
/* Plantilla de un Modal */
export const ModalLayout = ({ canClose = true, children, icon, open, setOpen = () => {}, title, width }) => (
    <Transition.Root as={Fragment} show={open}>
        <Dialog as='div' className='fixed z-0 inset-0 overflow-y-auto' onClose={setOpen}>
            <div className="px-4 pt-4 pb-20 min-h-screen flex items-end justify-center text-center sm:p-0 sm:block">
                <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in duration-300'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                >
                    <Dialog.Overlay className='fixed inset-0 bg-black bg-opacity-[0.45] transition-opacity' />
                </Transition.Child>

                {// CONDICIONAL
                /* Estado de la Habilitación del Cierre para el Modal */
                canClose
                    ? (<>
                        <span aria-hidden='true' className='hidden sm:inline-block sm:align-middle sm:h-screen'>&#8203;</span>
                        
                        <ModalContentLayout icon={icon} title={title} width={width}>{ children }</ModalContentLayout>
                    </>)
                    : (
                        <div className="fixed z-40 inset-0 overflow-y-auto">
                            <div className="p-4 min-h-full flex items-center justify-center text-center sm:p-0">
                                <ModalContentLayout icon={icon} title={title} width={width}>{ children }</ModalContentLayout>
                            </div>
                        </div>
                    )
                }
            </div>
        </Dialog>
    </Transition.Root>
);
