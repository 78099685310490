import { lazy, Suspense } from 'react';
/* Router */
import { Route, Routes } from 'react-router-dom';

// CONSTANTES
const BancosRouter = lazy(() => import('./bancos/BancosRouter'));                   /* Módulo de Bancos */
const ClientesRouter = lazy(() => import('./clientes/ClientesRouter'));             /* Módulo de Clientes */
const CobranzasRouter = lazy(() => import('./cobranza/CobranzasRouter'));           /* Módulo de Cobranzas */
const ConceptosRouter = lazy(() => import('./cobranza/ConceptosRouter'));           /* Módulo de Conceptos */
const FacturasRouter = lazy(() => import('./cobranza/FacturasRouter'));             /* Módulo de Facturas */
const Home = lazy(() => import('../pages/Home'));                                   /* Inicio */
const PagosRouter = lazy(() => import('./cobranza/PagosRouter'));                   /* Módulo de Pagos */
const ReportesRouter = lazy(() => import('./reportes/ReportesRouter'));             /* Módulo de Reportes */

// COMPONENTE
/* Rutas de la Aplicación */
export const AppRouter = () => (
    <Routes>
        {/* Inicio */}
        <Route
            element={
                <Suspense fallback={<>...</>}>
                    <Home />
                </Suspense>
            }
            path='/'
        />

        {/* Módulo de Cobranza */}
        <Route
            element={
                <Suspense fallback={<>...</>}>
                    <ConceptosRouter />
                </Suspense>
            }
            path='conceptos/*'
        />
        <Route
            element={
                <Suspense fallback={<>...</>}>
                    <FacturasRouter />
                </Suspense>
            }
            path='facturas/*'
        />
        <Route
            element={
                <Suspense fallback={<>...</>}>
                    <PagosRouter />
                </Suspense>
            }
            path='pagos/*'
        />
        <Route
            element={
                <Suspense fallback={<>...</>}>
                    <CobranzasRouter />
                </Suspense>
            }
            path='cobranzas/*'
        />
        
        {/* Módulo de Bancos */}
        <Route
            element={
                <Suspense fallback={<>...</>}>
                    <BancosRouter />
                </Suspense>
            }
            path='bancos/*'
        />

        {/* Módulo de Clientes */}
        <Route
            element={
                <Suspense fallback={<>...</>}>
                    <ClientesRouter />
                </Suspense>
            }
            path='clientes/*'
        />

        {/* Módulo de Reportes */}
        <Route
            element={
                <Suspense fallback={<>...</>}>
                    <ReportesRouter />
                </Suspense>
            }
            path='reportes/*'
        />
    </Routes>
);
