/* Headless UI */
import { Transition } from '@headlessui/react';

// COMPONENTE
/* Transición de Derecha a Izquierda con Condición de Muestra */
export const TransitionRightLeftShow = ({ children, className, show }) => (
    <Transition
        className={className}
        enter='transition transform duration-1000 ease-in-out zoom-in'
        enterFrom='translate-x-4 opacity-0'
        enterTo='translate-x-0 opacity-100'
        leave='transition-opacity duration-1000 ease-in-out'
        leaveFrom='translate-x-0 opacity-100'
        leaveTo='translate-x-4 opacity-0'
        show={show}
    >
        { children }
    </Transition>
);
