import { Fragment } from 'react';
/* Icons */
import { FaSignOutAlt } from 'react-icons/fa';
import { MdMail } from 'react-icons/md';
/* Hooks */
import { useState } from 'react';
/* Utils */
import { images } from '../../utils/helpers';
/* Headless UI */
import { Menu, Transition } from '@headlessui/react';

// COMPONENTE
/* Menú de Usuario */
export const UserMenu = ({ data, handleLogout }) => {
    // CONSTANTES
    /* Estado del Menú */
    const [ open, setOpen ] = useState(false);

    // RETORNO
    return (
        <Menu as='div' className='relative inline-block text-left'>
            {/* Botón de Apertura Menú con Inicial del Usuario */}
            <button
                className="max-w-xs flex items-center justify-center bg-ming-light rounded-full shadow-lg"
                onClick={() => setOpen(!open)}
            >
                <div className="w-8 h-8 flex items-center justify-center text-white text-xl font-bold">{ data.user.name.charAt(0).toUpperCase() }</div>
            </button>

            <Transition
                as={Fragment}
                enter='transition ease-out duration-100'
                enterFrom='transform opacity-0 scale-95'
                enterTo='transform opacity-100 scale-100'
                leave='transition ease-in duration-75'
                leaveFrom='transform opacity-100 scale-100'
                leaveTo='transform opacity-0 scale-95'
                show={open}
            >
                {/* Menú */}
                <Menu.Items className='py-fit mt-4 w-60 z-50 absolute origin-top-right right-0 bg-white rounded-b-lg shadow-gray-lighter shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                    <div className="p-fit mt-6 block rounded-xl text-center">
                        <div className="mb-6 flex items-center justify-center">
                            <div className="bg-lavander-light rounded-full">
                                <img alt="Workflow" className="h-14" src={images.userWhite} /> 
                            </div>
                        </div>
                        <div className="mx-auto w-52 flex items-center justify-center text-ming text-xl font-bold">{ data.user.name }</div>
                        <div className="mt-1">
                            <p className="my-0.5 inline-flex items-center text-blue-darker text-sm">
                                <MdMail className='mt-0.5 mr-1.5 text-amber-400' /> { data.user.email }
                            </p>
                        </div>
                            
                        {/* Botón de Logout */}
                        <Menu.Item>
                            {({ active }) => (
                                <div className="mt-6 border-t border-gray-lighter rounded-b-md">
                                    <button
                                        className="px-6 py-2 w-full block inline-flex items-center rounded-b-lg text-ruby text-md text-left font-bold ease-linear transition-all duration-50 hover:bg-ruby hover:text-white"
                                        onClick={() => handleLogout()}
                                        type="button"
                                    >
                                        Salir <FaSignOutAlt className={`mx-1.5 ${ active ? 'text-white' : 'text-ruby' }`} />
                                    </button>
                                </div>
                            )}
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    );
}
