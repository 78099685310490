/* Context */
import { AuthContext } from '../context/authContext';
/* Router */
import { Navigate } from 'react-router-dom';
/* Hooks */
import { useContext } from 'react';

// COMPONENTE
/* Ruta Privada */
export const PrivateRoute = ({ children }) => {
    /* Contexto */
    const { user } = useContext(AuthContext);

    // RETORNO
    return user.logged ? children : <Navigate to='/login' />;
}
