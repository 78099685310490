import React from 'react';
/* Context */
import { AuthContext } from './auth/context/authContext';
import { AuthReducer } from './auth/context/authReducer';
/* Router */
import { AuthRouter } from './router/AuthRouter';
/* Hooks */
import { useEffect, useReducer, useRef } from 'react';
/* Services */
import CookieService from './services/CookieService';
/* React Toastify */
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// FUNCIONES
/* Inicializador */
const init = () => CookieService.get('user') || { logged: false };

// APLICACION
/* Dolos */
export const Dolos = () => {
    // CONSTANTES
    /* Reducer */
    const [ user, dispatch ] = useReducer(AuthReducer, {}, init);

    // VARIABLES
    /* Referencia de los Clicks */
    let clicks = useRef(0);

    // FUNCIONES
    /* Manejador del Estado de los Clicks */
    const clickState = () => clicks.current += 1;

    useEffect(() => {
        // CONDICIONAL
        /* Comprobación de la Existencia de un Usuario */
        if (!user) return;

        // CONSTANTES
        const expiresAt = 60 * 24;                              /* Tiempo de Expiración */
        const options = { expiresAt: 0, path: '/', };           /* Opciones */

        // VARIABLES
        /* Fecha Actual */
        let date = new Date();

        date.setTime(date.getTime() + (expiresAt * 60 * 1000));
        CookieService.set('user', JSON.stringify(user), options);
    }, [ user ]);

    // RETORNO
    return (
        <AuthContext.Provider value={{ user, dispatch }}>
            <div onClick={() => clickState()}>
                <AuthRouter clicks={clicks} />
            </div>

            <ToastContainer />
        </AuthContext.Provider>
    );
}
