import { Fragment } from 'react';
/* Headless UI */
import { Dialog, Transition } from '@headlessui/react';

// COMPONENTE
/* Plantilla del Contenido de un Modal */
export const ModalContentLayout = ({ children, icon, title, width = 'sm:max-w-lg' }) => (
    <Transition.Child
        as={Fragment}
        enter='ease-out duration-300'
        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
        enterTo='opacity-100 translate-y-0 sm:scale-100'
        leave='ease-in duration-300'
        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
    >
        <div className={`inline-block align-bottom bg-ming-lighter rounded-lg shadow-xl overflow-hidden transform transition-all text-left sm:my-8 sm:w-full ${width} sm:align-middle`}>
            <Dialog.Title className='px-4 py-4 flex items-center leading-6 text-ming text-lg font-bold lg:text-xl'>
                <span className="mx-2 text-2xl">{ icon }</span> { title }
            </Dialog.Title>

            <hr className="text-ming" />

            <div className="px-6 py-4 bg-white">{ children }</div>
        </div>
    </Transition.Child>
);
