// COMPONENTE
/* Pie de las Páginas */
export const Footer = () => (
    <footer className="px-4 mx-auto w-full max-w-8xl absolute bottom-0 sm:px-6 lg:px-8 print:hidden">
        <div className="py-6 border-t border-ming text-ming text-sm text-center sm:text-left">
            <span className="block sm:mr-1 sm:inline sm:ml-8">
                Sistema web de Cobranza &copy; 2023 <b>Dolos</b>.
            </span>
            <span className="block sm:inline">Todos los derechos reservados.</span>
        </div>
    </footer>
);
