import axios from 'axios';
/* Context */
import { AuthContext } from '../../auth/context/authContext';
/* Components */
import { FieldTransparentInput } from '../../components/inputs/FieldTransparentInput';
import { PasswordTransparentInput } from '../../components/inputs/PasswordTransparentInput';
import { TextIconButton } from '../../components/buttons/TextIconButton';
import { TransitionRightLeftShow } from '../../components/transitions/TransitionRightLeftShow';
import { TransitionUp } from '../../components/transitions/TransitionUp';
/* Hooks */
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
/* Services */
import { headers } from '../../services/Headers';
/* Types */
import { types } from '../../auth/types/types';
/* Utils */
import { endpoint, getGreeting, images, regexEmail, toastConfig } from '../../utils/helpers';
/* React Toastify */
import { toast } from 'react-toastify';

// CONSTANTES
/* Url */
const url = `${endpoint}/login`;

// PAGINA
/* Login */
export const Login = () => {
    /* Contexto */
    const { dispatch } = useContext(AuthContext);
    /* Manejo de Datos en los Inputs y el Submit */
    const { formState: { errors }, handleSubmit, register } = useForm();

    // CONSTANTES
    const [ loading, setLoading ] = useState(false);            /* Estado de Carga */
    const [ showForm, setShowForm ] = useState(false);          /* Estado de Muestra del Formulario */

    // FUNCIONES
    /* Manejador del Submit */
    const onSubmit = async (data) => {
        setLoading(true);

        await axios.post(url, data, headers())
        .then(async (response) => {
            // CONSTANTES
            const token = response.data.data.token;          /* Token */
            const user = response.data.data;            /* Usuario */
            const sendData = {                          /* Data deL Envío de la Acción */
                token: token,
                user: user,
            }

            setTimeout(() => {
                dispatch({
                    payload: sendData,
                    type: types.login,
                });
            }, 250);
            setLoading(false);
        })
        .catch((err) => {
            console.log(err, 'err onSubmit');
            toast.error('Opps, credenciales inválidas.', toastConfig);
            setLoading(false);
        });
    }

    useEffect(() => {
        // CONSTANTES
        /* Intervalo */
        const interval = setInterval(() => setShowForm(true), 1000);

        // RETORNO
        return () => clearInterval(interval);
    }, []);
    
    // RETORNO
    return (
        <div className="w-full min-h-screen relative flex flex-col items-center justify-center">
            <TransitionUp className='relative'>
                <div 
                    className="pr-40 w-full h-[28rem] relative absolute -left-20 -top-16 bg-cover bg-center rounded-xl shadow-gray shadow-2xl"
                    style={{ backgroundImage: `url(${images.background1})`}}
                >
                    <h1 className="absolute left-6 -top-28 bg-clip-text bg-gradient-to-r from-ming-light to-lavander text-transparent text-[5.2rem] font-extrabold">Dolos</h1>

                    <p className="pl-8 pr-96 pt-2 text-white text-4xl font-bold">Sistema de Cobranza</p>
                </div>

                <TransitionRightLeftShow className='absolute -right-8 top-2' show={showForm}>
                    {/* Formulario de Login */}
                    <form
                        className="px-14 py-8 w-96 bg-blue-darker rounded-xl shadow-gray shadow-2xl"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <div className="mb-10 ">
                            <img alt="Logo" className="mx-auto mb-4 w-16 h-auto" src={images.dolosLavander} />

                            <h2 className="text-white text-3xl text-center font-bold">{ getGreeting() }</h2>

                            <div className="mx-6 my-2 border border-ming-light" />

                            <h1 className="text-white text-xl text-center">Inicio de Sesión</h1>
                        </div>
                        <div className="mb-6">
                            <FieldTransparentInput
                                id='email'
                                name='email'
                                placeholder='Correo Electrónico'
                                register={register}
                                registerOptions={{
                                    pattern: {
                                        message: 'Debe ingresar un email válido',
                                        value: regexEmail,
                                    },
                                    required: true,
                                }}
                            />
                            {errors.email && (<>
                                {errors.email.type === 'pattern' && (<div className="px-2 py-0.5 text-ruby-light text-sm font-bold">{ errors.email.message }</div>)}
                                {errors.email.type === 'required' && (<div className="px-2 py-0.5 text-ruby-light text-sm font-bold">El campo Email es requerido</div>)}
                            </>)}
                        </div>
                        <div className="mb-6">
                            <PasswordTransparentInput
                                id='password'
                                name='password'
                                placeholder='Contraseña'
                                register={register}
                            />
                            {errors.password && (<div className="px-2 py-0.5 text-ruby-light text-sm font-bold">El campo Contraseña es requerido</div>)}
                        </div>
                        <div className="pt-8 flex items-center justify-center">
                            <TextIconButton
                                margin='m-0'
                                padding='px-10 py-1.5'
                                text={loading
                                    ? (<><div className='mx-2 w-5 h-5 border-b-2 border-white rounded-full animate-spin'></div> Cargando...</>)
                                    : 'Ingresar'
                                }
                                textSize='text-md'
                                type='submit'
                            />
                        </div>
                    </form>
                </TransitionRightLeftShow>
            </TransitionUp>

            {/* Footer */}
            <footer className="px-8 mx-auto w-full absolute bottom-0 bg-ming-lighter border-t border-ming">
                <div className="px-4 py-6 text-ming text-md text-center">
                    Todos los derechos reservados &copy; 2023 <b>Dolos</b>
                </div>
            </footer>
        </div>
    );
}
